$(document).foundation();


$(document).foundation({
    reveal: {
        callback: function(reveal) {                           
            $(document).foundation('equalizer', 'reflow');
        }
    },
    equalizer: {
        equalize_on_stack: true,
        act_on_hidden_el: true,
        after_height_change: function() {
            $(document).foundation('accordion', 'reflow');
        }
    }
});

$('[data-reveal]').on('open.zf.reveal', function() {
	$(document).foundation('equalizer', 'reflow');
});